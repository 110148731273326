/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@iconify/iconify@2.2.1/dist/iconify.min.js
 * - /npm/litepicker@2.0.12/dist/litepicker.js
 * - /npm/html2canvas@1.4.1/dist/html2canvas.min.js
 * - /npm/file-saver@2.0.5/dist/FileSaver.min.js
 * - /npm/simplemde@1.11.2/dist/simplemde.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
